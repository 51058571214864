import React, { useEffect, useState, Fragment } from "react"
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps"
import classNames from "classnames"

import ReportLayout from "../../components/ReportLayout/ReportLayout"
import styles from "./map.module.css"

const MapPage = ({ location }) => (
  <ReportLayout urlSearch={location.search}>
    {({ report }) => (
      <Fragment>
        <CustomMap
          report={report}
          googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.GATSBY_GOOGLE_MAPS_KEY}`}
          loadingElement={<div style={{ height: "100%" }} />}
          containerElement={<div className={styles.mapContainer} />}
          mapElement={<div style={{ height: "100%" }} />}
        />

        <div className={classNames("container", styles.blurb)}>
          {report.blurb}
        </div>
      </Fragment>
    )}
  </ReportLayout>
)

// ============================================================================
// SUB-COMPONENTS
// ============================================================================

const CustomMap = withScriptjs(
  withGoogleMap(({ report }) => {
    const [latLng, setLatLng] = useState({ lat: 0, lng: 0 })

    useEffect(() => {
      const geocoder = new window.google.maps.Geocoder()
      geocoder.geocode({ address: report.locality_full }, (results, status) => {
        if (status === "OK") {
          setLatLng({
            lat: results[0].geometry.location.lat(),
            lng: results[0].geometry.location.lng(),
          })
        }
      })
    }, [report])

    return (
      <GoogleMap
        defaultZoom={13}
        center={latLng}
        defaultOptions={{ scrollwheel: false, zoomControl: true }}
      >
        <Marker position={latLng} />
      </GoogleMap>
    )
  })
)

export default MapPage
